.table_data {
  width: 100%;

  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }

    thead {
      @media (max-width: 767px) {
        display: none;
      }

      tr {
        font-size: 1em;
        border-bottom: 1px solid #333333;

        &.minmax {
          font-size: 0.8em;
        }

        td {
          white-space: nowrap;
          border-bottom: 1px solid #333333;
          padding: 8px 5px;

          .red {
            color: #f86c6c;
          }

          .green {
            color: #537f15;
          }

          .orange {
            color: #e16f2f;
          }

          .black {
            color: #333333;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #dddddd;
        }

        @media (max-width: 767px) {
          display: flex;
          flex-direction: column;
          font-size: 1.1em;
        }

        td {
          padding: 8px 5px;
          @media (max-width: 767px) {
            padding:8px 10px;
          }

          .red {
            color: #f86c6c;
          }

          .green {
            color: #537f15;
            font-weight: 600;
          }

          .orange {
            color: #e16f2f;
          }

          .black {
            color: #333333;
          }

          a {
            color: #333333;
            text-decoration: none;
          }

          .typeLabel {
            display: none;
            @media (max-width: 767px) {
              display: inline-block;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}