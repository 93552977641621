* {box-sizing: border-box}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.row {
  clear: both;
  padding: 0 30px 15px;
  @media (max-width: 767px) {
    padding: 15px;
  }
}

.app_loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #cbf18f;
    animation: app_loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

    &:nth-child(1) {
      left: 8px;
      animation-delay: -0.24s;
    }

    &:nth-child(2) {
      left: 32px;
      animation-delay: -0.12s;
    }

    &:nth-child(3) {
      left: 56px;
      animation-delay: 0s;
    }
  }
}

.data_table {
  width: 100%;
  color: #333333;
}


.table_filter {
  select {
    padding: 10px 5px;
    font-size: 1.1em;
    width: 300px;
    border-radius: 0;
    color: #333333;
    line-height: 1.2em;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

@keyframes app_loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}